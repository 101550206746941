import { Component, createContext } from "react";
import { sortByNumberOfJetpasses } from "components/helpers";
import { extract, isArray, EventAggregator } from "@parksandresorts/core";

const ADD_NOTIFICATION = "ADD_NOTIFICATION";

const defaultValues = {
  guests: [
    {
      name: "Gäst 1",
      id: 0,
      color: null,
      entryPasses: [],
      jetPasses: [],
      numberOfEntryTickets: 0,
      numberOfJetpasses: 0,
      entryPassSelectionComplete: true,
      jetpassSelectionComplete: true,
    },
  ],
  allEntryTicketsSelectionComplete: false,
  allJetpassesSelectionComplete: false,
  allSelectionComplete: false,
};
const JetpassContext = createContext({
  ...defaultValues,
  setValue: function () {},
});
const JetpassContextConsumer = JetpassContext.Consumer;

// Blue -     Purple -    Red -    Yellow -      Orange -   Teal -   Pink -    Light Blue - Dark Red - Black
const GUEST_COLORS = [
  "#3a7ae0",
  "#995fa3",
  "#f26157",
  "#f9db6d",
  "#FFA845",
  "#02c39a",
  "#f865b0",
  "#7a9cc6",
  "#852525",
  "#1b1725",
];

class JetPassContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectTimeSlot: this.selectTimeSlot.bind(this),
      removeTimeSlot: this.removeTimeSlot.bind(this),
      getGuestWithMostJetpass: this.getGuestWithMostJetpass.bind(this),
      createInitialGuests: this.createInitialGuests.bind(this),
      setNamesForGuests: this.setNamesForGuests.bind(this),
      setValue: this.setValue.bind(this),
      resetToDefault: this.resetToDefault.bind(this),
      clearCurrentSelectionForGuests: this.clearCurrentSelectionForGuests.bind(this),
      ...defaultValues,
    };
  }

  resetToDefault() {
    this.setState(defaultValues);
  }

  setValue(value, callback) {
    this.setState(value, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  setNamesForGuests(nameArray) {
    if (!isArray(nameArray)) {
      return;
    }

    const guestsWithNewNames = [];

    for (let i = 0; i < this.state.guests.length; i++) {
      if (nameArray[i] && nameArray[i].trim() !== "") {
        guestsWithNewNames.push({ ...this.state.guests[i], name: nameArray[i] });
      } else {
        guestsWithNewNames.push({ ...this.state.guests[i] });
      }
    }

    this.setState({ guests: guestsWithNewNames });
  }

  createInitialGuests(guests) {
    const extendedGuests = [];
    let colorIndex = 0;

    for (let i = 0; i < guests.length; i++, colorIndex++) {
      if (colorIndex > GUEST_COLORS.length - 1) {
        colorIndex = 0;
      }

      const guest = guests[i];
      const entryPasses = extract(guest, "entryPasses", []);
      const jetPasses = extract(guest, "jetPasses", []);
      const timeslotId = extract(guest, "product.timeSlotId", null)
        ? extract(guest, "product.timeSlotId", null)
        : extract(guest, "product.timeslotId", null);

      const extendedGuest = {
        id: i,
        name: guest.name ? guest.name : null,
        entryPasses: guest.entryPasses ? guest.entryPasses : [],
        jetPasses: guest.jetPasses ? guest.jetPasses : [],
        color: GUEST_COLORS[colorIndex],
        numberOfEntryTickets: guest.numberOfEntryTickets ? guest.numberOfEntryTickets : 0,
        numberOfJetpasses: guest.numberOfJetpasses ? guest.numberOfJetpasses : 0,
        jetpassSelectionComplete: jetPasses.length >= guest.numberOfJetpasses ? true : false,
        entryPassSelectionComplete: entryPasses.length >= guest.numberOfEntryTickets ? true : false,
        productInfo: {
          glkId: extract(guest, "product.glkId", null),
          jetshopId: extract(guest, "product.jetshopId", null),
          productName: extract(guest, "product.productName", null),
          productType: extract(guest, "product.productType", null),
          timeslotId: timeslotId,
        },
        productTimeLimit: guest.productTimeLimit,
        additionalInfo: guest.additionalInfo,
      };

      extendedGuests.push(extendedGuest);
    }

    this.setState({ guests: extendedGuests });
  }

  getGuestWithMostJetpass() {
    const guestsCopy = [...this.state.guests];
    guestsCopy.sort(sortByNumberOfJetpasses);
    return guestsCopy[0];
  }

  clearCurrentSelectionForGuests() {
    if (!isArray(this.state.guests)) {
      return;
    }

    const guestsWithClearedSelection = this.state.guests.map((guest) => {
      const entryPasses = [];
      const jetPasses = [];

      return {
        ...guest,
        entryPasses: entryPasses,
        jetPasses: jetPasses,
        entryPassSelectionComplete: entryPasses.length >= guest.numberOfEntryTickets ? true : false,
        jetpassSelectionComplete: jetPasses.length >= guest.numberOfJetpasses ? true : false,
      };
    });

    this.setState({ guests: guestsWithClearedSelection });
  }

  removeTimeSlot(attractionId, guestId, options = { selectForAll: true, entryPasses: false }) {
    const arrayToUpdate = options.entryPasses ? "entryPasses" : "jetPasses";
    const selectionCompleteToUpdate = options.entryPasses ? "entryPassSelectionComplete" : "jetpassSelectionComplete";
    const totalPassesToFetch = options.entryPasses ? "numberOfEntryTickets" : "numberOfJetpasses";

    let guestsToUpdate = [];

    if (options.selectForAll) {
      guestsToUpdate = this.state.guests.map((guest) => {
        const updatedArray = guest[arrayToUpdate].filter((item) => item.attractionId !== attractionId);
        const selectionComplete = updatedArray.length >= guest[totalPassesToFetch] ? true : false;
        return {
          ...guest,
          [arrayToUpdate]: updatedArray,
          [selectionCompleteToUpdate]: selectionComplete,
        };
      });

      this.setState({ guests: guestsToUpdate });
    } else {
      const guestIndex = this.state.guests.findIndex((g) => g.id === guestId);
      const guest = this.state.guests[guestIndex];

      const updatedArray = guest[arrayToUpdate].filter((item) => item.attractionId !== attractionId);
      const selectionComplete = updatedArray.length >= guest[totalPassesToFetch] ? true : false;

      const updatedGuest = {
        ...guest,
        [arrayToUpdate]: updatedArray,
        [selectionCompleteToUpdate]: selectionComplete,
      };

      guestsToUpdate = [...this.state.guests];
      guestsToUpdate[guestIndex] = updatedGuest;
    }

    const guestSelectionCompleteResult = this.getSelectionCompleteForGuests(guestsToUpdate);

    this.setState({
      guests: guestsToUpdate,
      allJetpassesSelectionComplete: guestSelectionCompleteResult.allJetpassesSelectionComplete,
      allEntryTicketsSelectionComplete: guestSelectionCompleteResult.allEntryTicketsSelectionComplete,
      allSelectionComplete: guestSelectionCompleteResult.allSelectionComplete,
    });
  }

  selectTimeSlot(timeSlot, attractionId, guestId, options = { selectForAll: true, entryPasses: false }) {
    const arrayToUpdate = options.entryPasses ? "entryPasses" : "jetPasses";
    const totalPassesToFetch = options.entryPasses ? "numberOfEntryTickets" : "numberOfJetpasses";

    let guestsToUpdate = [];

    if (options.selectForAll) {
      let numberOfJetpassRemoved = 0;

      guestsToUpdate = this.state.guests.map((guest) => {
        const updatedGuestResult = this.selectTimeSlotForGuest(
          guest,
          timeSlot,
          attractionId,
          arrayToUpdate,
          totalPassesToFetch,
          options.entryPasses,
        );
        numberOfJetpassRemoved = updatedGuestResult.numberOfJetpassRemoved;

        return updatedGuestResult.updatedGuest;
      });

      if (numberOfJetpassRemoved > 0) {
        this.notifyRemovedJetpasses(numberOfJetpassRemoved);
      }
    } else {
      const guestIndex = this.state.guests.findIndex((g) => g.id.toString() === guestId.toString());
      const guest = this.state.guests[guestIndex];
      const updatedGuestResult = this.selectTimeSlotForGuest(
        guest,
        timeSlot,
        attractionId,
        arrayToUpdate,
        totalPassesToFetch,
        options.entryPasses,
      );

      if (updatedGuestResult.numberOfJetpassRemoved > 0) {
        this.notifyRemovedJetpasses(updatedGuestResult.numberOfJetpassRemoved);
      }

      guestsToUpdate = [...this.state.guests];
      guestsToUpdate[guestIndex] = updatedGuestResult.updatedGuest;
    }

    const guestSelectionCompleteResult = this.getSelectionCompleteForGuests(guestsToUpdate);

    this.setState({
      guests: guestsToUpdate,
      allJetpassesSelectionComplete: guestSelectionCompleteResult.allJetpassesSelectionComplete,
      allEntryTicketsSelectionComplete: guestSelectionCompleteResult.allEntryTicketsSelectionComplete,
      allSelectionComplete: guestSelectionCompleteResult.allSelectionComplete,
    });
  }

  getSelectionCompleteForGuests(guests) {
    let allJetpassesSelectionComplete = true;
    let allEntryTicketsSelectionComplete = true;

    for (const guest of guests) {
      if (!guest.entryPassSelectionComplete) {
        allEntryTicketsSelectionComplete = false;
      }

      if (!guest.jetpassSelectionComplete) {
        allJetpassesSelectionComplete = false;
      }
    }

    const allSelectionComplete = allEntryTicketsSelectionComplete && allJetpassesSelectionComplete;

    return {
      allJetpassesSelectionComplete: allJetpassesSelectionComplete,
      allEntryTicketsSelectionComplete: allEntryTicketsSelectionComplete,
      allSelectionComplete: allSelectionComplete,
    };
  }

  notifyRemovedJetpasses(numberOfJetpassesRemoved) {
    setTimeout(() => {
      EventAggregator.publish(ADD_NOTIFICATION, {
        headline: "Information",
        body: `${numberOfJetpassesRemoved} st JetPass har tagits bort eftersom de låg före din nya entretid, du kan välja nya tider i nästa steg.`,
        displayTime: 10,
      });
    }, 1000);
  }

  selectTimeSlotForGuest(guest, timeSlot, attractionId, arrayToUpdate, totalPassesToFetch, isUpdatingEntryPasses) {
    if (guest[totalPassesToFetch] === 0) {
      return {
        updatedGuest: {
          ...guest,
        },
        numberOfJetpassRemoved: 0,
      };
    }
    const timeSlotIndex = guest[arrayToUpdate].findIndex((item) => item.attractionId === attractionId);
    let newSelectedPasses = null;

    if (timeSlotIndex === -1) {
      newSelectedPasses = [...guest[arrayToUpdate]];
    } else {
      newSelectedPasses = guest[arrayToUpdate].filter((item) => item.attractionId !== attractionId);
    }

    newSelectedPasses.push({
      id: timeSlot.timeSlotId,
      productId: timeSlot.productId,
      attractionId: attractionId,
      startDateTime: timeSlot.timeSlotBegin,
      endDateTime: timeSlot.timeSlotEnd,
      attractionName: timeSlot.attractionName,
    });

    const numberOfSelectedPasses = newSelectedPasses.length;
    const selectionComplete = numberOfSelectedPasses >= guest[totalPassesToFetch] ? true : false;
    if (isUpdatingEntryPasses) {
      const selectedPasses = guest.jetPasses;
      const selectedPassesWithoutTimesBeforeNewEntryTime = selectedPasses.filter(
        (pass) => pass.startDateTime > timeSlot.timeSlotBegin,
      );
      const jetpassSelectionComplete = selectedPassesWithoutTimesBeforeNewEntryTime.length >= guest.numberOfJetpasses;
      const numberOfJetpassRemoved = selectedPasses.length - selectedPassesWithoutTimesBeforeNewEntryTime.length;

      return {
        updatedGuest: {
          ...guest,
          entryPasses: newSelectedPasses,
          entryPassSelectionComplete: selectionComplete,
          jetpassSelectionComplete: jetpassSelectionComplete,
          jetPasses: selectedPassesWithoutTimesBeforeNewEntryTime,
        },
        numberOfJetpassRemoved: numberOfJetpassRemoved,
      };
    }

    return {
      updatedGuest: {
        ...guest,
        jetPasses: newSelectedPasses,
        jetpassSelectionComplete: selectionComplete,
      },
      numberOfJetpassRemoved: 0,
    };
  }

  render() {
    return <JetpassContext.Provider value={this.state}>{this.props.children}</JetpassContext.Provider>;
  }
}

export default JetpassContext;
export { JetpassContext, JetpassContextConsumer, JetPassContextProvider };
