import { graphql, useStaticQuery } from "gatsby";
import {
  ProductStore,
  ShowStore,
  RideStore,
  RestaurantStore,
  PlaceholderImageHelper,
  extract,
  JetshopStore,
  TextBlockStore,
} from "@parksandresorts/core";

const StaticDataFactory = (props) => {
  const data = useStaticQuery(query);

  JetshopStore.populate(data?.allJetshopProduct?.nodes ?? []);
  ProductStore.populateStore(extract(data, "allContentfulProductBlock.edges"));
  ShowStore.populateStore(extract(data, "allContentfulShowBlock.edges"));
  RideStore.populateStore(extract(data, "allContentfulRideBlock.edges"));
  RestaurantStore.populateStore(extract(data, "allContentfulRestaurantBlock.edges"));
  TextBlockStore.populateStore(extract(data, "allContentfulTextBlock.nodes"));

  PlaceholderImageHelper.setPlaceholderImages({
    eventRow: "/grona-lund-logo.svg",
  });

  return <>{props.children}</>;
};

const query = graphql`
  {
    allJetshopProduct {
      nodes {
        glkId
        productId
        price
        previousPrice
        attributes {
          attributeId
          articleNumber
          attributeName
          isBuyable
          stockStatus
          price
          priceString
          discountPrice
          discountPriceString
        }
        stockLevel
        isBuyable
        stockStatus
        productName
        minimumQuantity
      }
    }
    allContentfulProductBlock(filter: { productName: { ne: null } }) {
      edges {
        node {
          ...ProductBlock
        }
      }
    }
    allContentfulShowBlock {
      edges {
        node {
          contentful_id
          node_locale
          internalTitle
          id
          sortIndex
          preHeading
          title
          preamble {
            preamble
          }
          label
          labelComponentTheme
          shortDescriptionInList
          pageLink {
            slug
          }
          imageInList {
            description
            gatsbyImageData(width: 132, height: 132, layout: FIXED, resizingBehavior: FILL)
            file {
              url
            }
          }
          tags {
            title
          }
          length
          location
          product {
            contentful_id
            productPageLink {
              slug
            }
          }
          mapLink {
            id
            title
            smallDeviceTitle
            internalLink {
              slug
            }
            externalUrl
            openInNewTab
            image {
              file {
                url
              }
            }
            asset {
              file {
                url
              }
            }
            icon
          }
          calendarText {
            calendarText
          }
          calendarLink
          customDateText
          customDateTime
          hideEventDateAndOnlyShowCustomText
          showInPurchaseFlow
          showProductDescriptionTextInPurchaseFlow
        }
      }
    }
    allContentfulRideBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          id
          contentful_id
          node_locale
          sortIndex
          rideId
          title
          preamble {
            preamble
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          shortDescriptionInList
          imageInList {
            file {
              url
            }
          }
          label
          tags {
            title
          }
          minimumHeight
          ageRequirement
          pageLink {
            slug
          }
          jetpassSelectSlotText
        }
      }
    }
    allContentfulRestaurantBlock(filter: { title: { ne: null } }) {
      edges {
        node {
          node_locale
          ...RestaurantBlock
        }
      }
    }
    allContentfulTextBlock(filter: { richText: { raw: { ne: null } } }) {
      nodes {
        id
        contentful_id
        node_locale
        internalTitle
        componentTheme
        richText {
          raw
          references {
            __typename
            ...Link
            ...Asset
          }
        }
      }
    }
  }
`;

export default StaticDataFactory;
